import type { MutableRefObject } from "react";
import { useLayoutEffect, useRef, useState } from "react";

const useViewportOffset = (): [MutableRefObject<HTMLDivElement>, DOMRect] => {
  const ref = useRef<HTMLDivElement>();
  const [box, setBox] = useState<DOMRect>();
  if (process.browser) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      if (ref.current) {
        const measure = () =>
          window.requestAnimationFrame(() => {
            if (ref.current) {
              const rect = ref.current.getBoundingClientRect();
              setBox(rect);
            }
          });
        measure();
        window.addEventListener("resize", measure);

        return () => {
          window.removeEventListener("resize", measure);
        };
      }
    }, [ref]);
  }

  return [ref, box];
};

export default useViewportOffset;
