import Link from "next/link";
import React from "react";
import styled from "styled-components";
import { selectColor } from "../../../utils/themeUtils";
import useProductTileNavItem from "../hooks/useProductTileNavItem";
import type { MinProduct } from "../utils/makeProductNavItem";

type ProductTileLinkProps = {
  product: MinProduct;
  relativeLinks?: boolean;
  color: string;
};

const A = styled.a`
  text-decoration: none;
  color: ${selectColor("textBlack")};
  display: flex;
  overflow: hidden;
`;

const ProductTileLink: React.FC<ProductTileLinkProps> = ({
  product,
  color,
  relativeLinks = false,
  children,
}) => {
  const navItem = useProductTileNavItem(product, color, relativeLinks);
  if (!navItem) {
    return <A>{children}</A>;
  }
  return (
    <Link href={navItem.href} as={navItem.path} passHref>
      <A>{children}</A>
    </Link>
  );
};

export default ProductTileLink;
