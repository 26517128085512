import React from "react";
import Rectangle from "react-rectangle";
import styled from "styled-components";

const Base = styled(Rectangle)``;

const Image = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  margin: auto;
  object-fit: contain;
`;

export interface ProductImageProps {
  style?: React.CSSProperties;
  className?: string;
  src: string;
  alt?: string;
}

const ProductImage: React.FC<ProductImageProps> = ({
  style,
  className,
  src,
  alt,
}) => {
  return (
    <Base style={style} className={className} aspectRatio={16 / 10}>
      {src ? <Image src={src} alt={alt} loading="lazy" /> : null}
    </Base>
  );
};

export default React.memo(ProductImage);
