import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  BaseProductNavItemParts,
  VariantProductNavItemParts,
} from "../../product/queries";
import { makeCanonicalPath } from "../../product/utils/makeCanonicalUrl";
import makeProductNavItem from "../../product/utils/makeProductNavItem";

import type {
  GetProductForNavItem,
  GetProductForNavItemVariables,
} from "./types/GetProductForNavItem";

const QUERY = gql`
  query GetProductForNavItem($productId: ID!) {
    product(productId: $productId) {
      _id
      texts {
        _id
        slug
      }

      ... on ShopSimpleProduct {
        ...VariantProductNavItemParts
      }
      ... on ShopConfigurableProduct {
        ...BaseProductNavItemParts
      }
    }
  }
  ${VariantProductNavItemParts}
  ${BaseProductNavItemParts}
`;
const useProductCanonicalNavItem = (
  productId: string,
  color?: string,
  skip?: boolean,
) => {
  const { data } = useQuery<
    GetProductForNavItem,
    GetProductForNavItemVariables
  >(QUERY, { skip: skip, variables: { productId } });

  return data?.product?.__typename === "ShopConfigurableProduct" ||
    data?.product?.__typename === "ShopSimpleProduct"
    ? makeProductNavItem(data.product, makeCanonicalPath(data.product), color)
    : null;
};
export default useProductCanonicalNavItem;
