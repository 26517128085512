import styled from "styled-components";
import mediaQueries from "../../../utils/mediaQueries";
import { selectColor } from "../../../utils/themeUtils";

import ProductTile, { Placeholder } from "./ProductTile";

const defaultHeight = 350;
const defaultPadding = 15;

export const ProductTileWrapper = styled.div<{
  height?: number;
  padding?: number;
}>`
  background: ${selectColor("white")};
  height: ${({ height }) => `${height ? height : defaultHeight}px`};
  padding: ${({ padding }) => `${padding ? padding : defaultPadding}px`};
  border-radius: 7px;
  overflow: hidden;

  ${mediaQueries.desktop`
    padding: 0;
  `}
`;

export const StyledProductTile = styled(ProductTile)`
  height: ${defaultHeight}px;
`;

export const StyledPlaceholder = styled(Placeholder)<{ height?: number }>`
  height: ${({ height }) => `${height ? height : defaultHeight}px`};
`;
