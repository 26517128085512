import { useRouter } from "next/router";
import type { MinProduct } from "../utils/makeProductNavItem";
import makeProductNavItem from "../utils/makeProductNavItem";
import useProductCanonicalNavItem from "./useProductCanonicalNavItem";

// two cases:
// on normal lists, we don't want to fetch the canonical path, as it is currently a bit expensive.
// we actually don't need it as we have the parent path
// But if we show the tile without a parent path, we need the canonical link.
// this is the case e.g. for "related products"
const useProductTileNavItem = (
  product: MinProduct,
  color?: string,
  relativeLinks?: boolean,
) => {
  const isVariantId = product?._id.includes(".");
  const router = useRouter();
  const colorParam = isVariantId || color === "_colorless_" ? null : color;

  const maybeCanonicalNavItem = useProductCanonicalNavItem(
    product?._id,
    colorParam,
    relativeLinks, // skip param
  );
  if (!product) return null;
  if (relativeLinks) {
    return makeProductNavItem(
      product,
      router?.asPath?.split("?")[0],
      colorParam,
    );
  } else {
    return maybeCanonicalNavItem;
  }
};
export default useProductTileNavItem;
