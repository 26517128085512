import { times } from "lodash-es";
import React from "react";
import styled from "styled-components";
import { selectColor } from "../../../utils/themeUtils";
const Base = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export interface GalleryIndicatorsProps {
  style?: React.CSSProperties;
  className?: string;
  onSelect: (index: number) => void;
  activeIndices: number[];
  count: number;
}

const SIZE = 10;
const Circle = styled.div<{ selected: boolean }>`
  width: ${SIZE}px;
  height: ${SIZE}px;
  border-radius: 50%;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
  background-color: ${selectColor((p) => (p.selected ? "black" : "petrol"))};
  transition: 0.3s;
`;

const GalleryIndicators: React.FC<GalleryIndicatorsProps> = ({
  style,
  className,
  count,
  onSelect,
  activeIndices,
}) => (
  <Base style={style} className={className}>
    {times(count).map((index) => (
      <Circle
        key={index}
        selected={activeIndices.includes(index)}
        onClick={() => onSelect(index)}
      />
    ))}
  </Base>
);

export default GalleryIndicators;
