import { throttle } from "lodash-es";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import type { PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import type { Color } from "../../../config/theme";

import {
  hideScrollbars,
  iff,
  selectColor,
  selectGridWidth,
  selectSize,
  selectZIndex,
} from "../../../utils/themeUtils";
import BackIconLight from "../../icons/components/BackIconLight";
import NextIconLight from "../../icons/components/NextIconLight";
import useViewportOffset from "../hooks/useViewportOffset";
import GalleryIndicators from "./GalleryIndicators";

type WidthInColumns = 1 | 2 | 3 | 4 | 6 | 12;
export type ScrollabelGalleryProps = PropsWithChildren<{
  style?: React.CSSProperties;
  className?: string;
  /**
   * the width of one tile in terms of the 12-column grid.
   * E.g. setting it to 3 will make one tile occupy 3 columns, and will show 4 tiles when shown fully on desktop
   */
  widthInColumns?: WidthInColumns;
  /**
   * whethr to show indicators on the bottom
   */
  showIndicators?: boolean;

  buttonsBackgroundColor?: Color;
  separatorColor?: Color;
  startIndex?: number;
}>;

type ItemProps = {
  widthInColumns: WidthInColumns;

  isLast: boolean;

  separatorColor: Color;
};

const separatorWidth = 2;
const Item = styled.div<ItemProps>`
  flex-grow: 0;
  position: relative;
  flex-shrink: 0;
  max-width: 100%; /* for mobile */
  width: ${(props) => selectGridWidth(props.widthInColumns ?? 4)(props)}px;

  ${iff((p) => p.separatorColor && !p.isLast)`
    &:after {
    content: "";
    position: absolute;
    top: 0px;
    right: ${selectSize(
      "desktopGrid",
      (v) => -(v.gutter + separatorWidth) / 2,
    )}px;

    height: 100%;
    width: ${separatorWidth}px;
    background-color: ${selectColor((p) => p.separatorColor)};
  }
  `}
`;

const buttonWidth = 70;
const buttonHeight = 70;

const Items = styled.div<{ separatorColor?: Color }>`
  overflow: auto;
  ${hideScrollbars};
  display: flex;
  gap: ${selectSize("desktopGrid", (v) => v.gutter)}px;
  scroll-snap-type: x mandatory;

  & > * {
    scroll-snap-align: start;
  }

  & > :last-child {
    border-right: none;
  }
`;

const useScroller = () => {
  const containerRef = useRef<HTMLDivElement>();
  const [visibleElementIndices, setVisibleElementIndices] = useState([0]);
  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const containerEl = containerRef.current;
    const calcVisible = () => {
      const visibleIndices = [];
      Array.from(containerEl.children).forEach((el: HTMLElement, index) => {
        if (
          el.offsetLeft >= containerEl.scrollLeft &&
          containerEl.scrollLeft + containerEl.clientWidth >
            el.offsetLeft + el.clientWidth - 30 // don't know why 30, probably some padding ?
        ) {
          visibleIndices.push(index);
        }
      });

      setVisibleElementIndices(visibleIndices);
    };
    const calcVisibleThrottled = throttle(calcVisible, 50);
    // do it once
    calcVisible();
    containerEl.addEventListener("scroll", calcVisibleThrottled);
    window.addEventListener("resize", calcVisibleThrottled);

    return () => {
      containerEl.removeEventListener("scroll", calcVisibleThrottled);
      window.removeEventListener("resize", calcVisibleThrottled);
    };
  }, [containerRef]);
  const scrollTo = useCallback(
    (index, behavior = "smooth") => {
      if (containerRef.current?.scrollTo) {
        const targetElement = Array.from(containerRef.current.children)[
          index
        ] as HTMLElement;
        if (targetElement) {
          containerRef.current.scrollTo({
            left: targetElement.offsetLeft,
            behavior,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [containerRef.current?.children],
  );

  return {
    containerRef,
    scrollTo,
    visibleElementIndices,
  };
};

const buttonStyle: any = css`
  appearance: none;
  background: none;
  cursor: pointer;
  border: none;
  position: absolute;
  z-index: ${selectZIndex("scrollableGalleryButton")};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: ${buttonWidth}px;
  height: ${buttonHeight}px;
  top: 50%;
  margin-top: -${buttonHeight / 2}px;

  &:focus {
    outline: none;
  }
  transition: 0.3s;
  opacity: ${(p: any) => (p.show ? 1 : 0)};
`;

const PreviousButton = styled.button.attrs({
  children: (
    <BackIconLight
      color="petrol"
      colorHover="petrolHover"
      style={{ height: 45 }}
    />
  ),
})<{ show: boolean; backgroundColor: Color }>`
  ${buttonStyle};
`;

const NextButton = styled.button.attrs({
  children: (
    <NextIconLight
      color="petrol"
      colorHover="petrolHover"
      style={{ height: 45 }}
    />
  ),
})<{ show: boolean; backgroundColor: Color }>`
  ${buttonStyle};
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
`;

type GalleryHandle = { scrollToIndex: (i: number) => void };
export const useGalleryRef = () => useRef<GalleryHandle>();

const ScrollabelGallery = forwardRef<GalleryHandle, ScrollabelGalleryProps>(
  (
    {
      children,
      widthInColumns = 4,
      showIndicators,
      buttonsBackgroundColor = "white",
      separatorColor,
      startIndex,
      style,
      className,
    },
    ref,
  ) => {
    const [baseRef, box] = useViewportOffset();
    const boxWidth = box?.width ?? 0;
    const boxMargin = process.browser
      ? (document.body.clientWidth - boxWidth) / 2
      : 0;
    const buttonFitsInBoxMargin = process.browser
      ? buttonWidth <= boxMargin
      : true;
    const buttonOffset = buttonFitsInBoxMargin ? -buttonWidth : -boxMargin;

    const { containerRef, scrollTo, visibleElementIndices } = useScroller();
    useImperativeHandle(ref, () => ({
      scrollToIndex: (index) => {
        scrollTo(index);
      },
    }));
    const theChildren = React.Children.toArray(children);
    const numElements = theChildren.length;
    const pageSize = visibleElementIndices.length;
    const [firstVisibleIndex = 0] = visibleElementIndices;
    const lastVisibleIndex =
      visibleElementIndices[visibleElementIndices.length - 1];
    const gotoPreviousPage = useCallback(() => {
      const targetIndex = Math.max(0, firstVisibleIndex - pageSize);
      scrollTo(targetIndex);
    }, [scrollTo, pageSize, firstVisibleIndex]);

    const gotoNextPage = useCallback(() => {
      const targetIndex = Math.min(
        numElements - 1,
        firstVisibleIndex + pageSize,
      );
      scrollTo(targetIndex);
    }, [scrollTo, firstVisibleIndex, pageSize, numElements]);

    const isAtstart = firstVisibleIndex === 0;
    const isAtEnd = lastVisibleIndex === numElements - 1;

    useEffect(() => {
      if (startIndex) {
        scrollTo(Math.min(numElements - 1, Math.max(0, startIndex)), "auto");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div ref={baseRef} style={style} className={className}>
        <ContentWrapper>
          <PreviousButton
            onClick={gotoPreviousPage}
            show={!isAtstart}
            backgroundColor={buttonsBackgroundColor}
            style={{ left: buttonOffset }}
          />
          <NextButton
            onClick={gotoNextPage}
            show={!isAtEnd}
            backgroundColor={buttonsBackgroundColor}
            style={{ right: buttonOffset }}
          />
          <Items ref={containerRef}>
            {theChildren.map((child, index) => (
              <Item
                key={index}
                widthInColumns={widthInColumns}
                isLast={index === numElements - 1}
                separatorColor={separatorColor}
              >
                {child}
              </Item>
            ))}
          </Items>
          {showIndicators ? (
            <GalleryIndicators
              onSelect={scrollTo}
              activeIndices={visibleElementIndices}
              count={numElements}
            />
          ) : null}
        </ContentWrapper>
      </div>
    );
  },
);

export default ScrollabelGallery;
