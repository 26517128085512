import React from "react";
import styled from "styled-components";
import Price from "../../product/components/Price";
import type { BaseProductTileParts_vpsActiveColorVariants } from "../queries/types/BaseProductTileParts";
import { isLowPrice } from "../utils/productTagHelpers";

const Base = styled.div``;

const Prices = styled.div`
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: top;
`;

export interface ProductOverviewPriceProps {
  style?: React.CSSProperties;
  className?: string;
  colorVariant: BaseProductTileParts_vpsActiveColorVariants;
}

const ProductOverviewPrice: React.FC<ProductOverviewPriceProps> = ({
  style,
  className,
  colorVariant,
}) => {
  const minCatalogPrice = Math.min(
    ...colorVariant.products.map((p) => p.vpsCatalogPrice?.amount),
  );
  const minSimulatedPrice = Math.min(
    ...colorVariant.products.map((p) => p.vpsDiscountedCatalogPrice?.amount),
  );
  const maxCatalogPrice = Math.max(
    ...colorVariant.products.map((p) => p.vpsCatalogPrice?.amount),
  );
  const maxSimulatedPrice = Math.max(
    ...colorVariant.products.map((p) => p.vpsDiscountedCatalogPrice?.amount),
  );
  const everyVariantHasSamePrice =
    minCatalogPrice === maxCatalogPrice &&
    minSimulatedPrice === maxSimulatedPrice;
  const isDiscounted = minCatalogPrice > minSimulatedPrice;
  const currency = colorVariant.products[0]?.vpsCatalogPrice?.currency;
  return (
    <Base style={style} className={className}>
      <Prices>
        <Price
          style={{ marginTop: 0, marginRight: 8 }}
          prefix={!everyVariantHasSamePrice ? "Ab " : null}
          font="productPrice"
          price={{
            amount: minSimulatedPrice,
            currency: currency,
          }}
          discount={isDiscounted || colorVariant.products.some(isLowPrice)}
        />
        {isDiscounted ? (
          <Price
            style={{ marginTop: 0 }}
            original
            font="productPriceOriginal"
            price={{
              amount: minCatalogPrice,
              currency: currency,
            }}
          />
        ) : null}
      </Prices>
    </Base>
  );
};

export default ProductOverviewPrice;
