import React from "react";
import styled from "styled-components";
import type { Color } from "../../../config/theme";
import { selectColor } from "../../../utils/themeUtils";

const SVG = styled.svg<{ color: Color; colorHover?: Color }>`
  height: 45px;
  & path {
    stroke: ${selectColor((p) => p.color)};
  }
  &:hover path {
    stroke: ${selectColor((p) => p.colorHover ?? p.color)};
  }
`;

export interface NextIconLightProps {
  style?: React.CSSProperties;
  className?: string;
  color?: Color;
  colorHover?: Color;
}

const NextIconLight: React.FC<NextIconLightProps> = ({
  style,
  className,
  color = "grey",
  colorHover = null,
}) => (
  <SVG
    viewBox="0 0 20 45"
    style={style}
    className={className}
    color={color}
    colorHover={colorHover}
  >
    <path
      d="M1 1l17 21.5L1 44"
      stroke="#478392"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
    />
  </SVG>
);

export default NextIconLight;
