import dynamic from "next/dynamic";
import React, { useState } from "react";
import Rectangle from "react-rectangle";
import styled, { css } from "styled-components";
import { useTranslation } from "../../../hooks/useTranslation";
import mediaQueries from "../../../utils/mediaQueries";
import { selectColor, selectFont } from "../../../utils/themeUtils";
import LoadingPlaceholder from "../../core/components/LoadingPlaceholder";
import AvailabilityIcon from "../../icons/components/AvailabilityIcon";
import type { BaseProductTileParts } from "../queries/types/BaseProductTileParts";
import { getMostOptimisticAvailability } from "../utils/getMostOptimisticAvailability";
import { isLowPrice, isMonthly } from "../utils/productTagHelpers";
import DiscountBadge from "./DiscountBadge";
import ProductImage from "./ProductImage";
import ProductOverviewPrice from "./ProductOverviewPrice";
import ProductTileLink from "./ProductTileLink";

const ProductTileColorVariants = dynamic(
  () => import("./ProductTileColorVariants"),
  { ssr: false },
);
const Base = styled.div`
  position: relative;
  border-radius: 7px;

  ${mediaQueries.desktop`
    padding: 15px;
  `}

  overflow: hidden;
  cursor: pointer;
  width: 100%;
`;

const TheIcon = styled(AvailabilityIcon)`
  position: absolute;
  top: 8px;
  right: 0;
  pointer-events: none;

  ${mediaQueries.desktop`
    top: 22px;
    right: 17px;
  `}
`;

const HoverEffect = styled.div`
  display: none;
  ${mediaQueries.desktop`
   display: block;
  `}
  background-color: ${selectColor("productHover")};
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
  z-index: 1;
`;

const PlaceholderBase = styled(Base)`
  background-color: ${selectColor("white")};
  height: 100%;
  padding: 15px;
`;

export const Placeholder: React.FC<{
  style?: React.CSSProperties;
  className?: string;
}> = ({ style, className }) => (
  <PlaceholderBase style={style} className={className}>
    <Rectangle aspectRatio={16 / 10}>
      <LoadingPlaceholder />
    </Rectangle>
    <LoadingPlaceholder
      font="productTileTitle"
      style={{ width: "80px", marginTop: 30, marginBottom: 40 }}
    />
  </PlaceholderBase>
);

const Title = styled.div`
  width: 100%;
  ${selectFont("productTileTitle")}
`;

const Labels = styled.div`
  top: 0px;
  left: 0px;
  ${mediaQueries.desktop`
    top: 15px;
    left: 15px;
  `}
  pointer-events: none;

  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 5;
`;

const LabelStyle = css`
  background-color: ${selectColor("sand")};
  border-radius: 5px;
  padding: 2px 8px;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${selectFont("productTag")};
`;

const HIGHLIGHT = styled.div`
  ${LabelStyle}
  color: ${selectColor("highlight")};
`;

const BUDGET = styled.div`
  ${LabelStyle}
  color: ${selectColor("budget")};
`;

const PREMIUM = styled.div`
  ${LabelStyle}
  color: ${selectColor("textBlack")};
`;

const NEW = styled.div`
  ${LabelStyle}
  color: ${selectColor("new")};
`;

const LabelTypes = {
  HIGHLIGHT,
  BUDGET,
  PREMIUM,
  NEW,
};

export interface ProductTileProps {
  style?: React.CSSProperties;
  className?: string;
  product: BaseProductTileParts;
  largeImage?: boolean;
  relativeLinks?: boolean;
}

const Tags: React.FC<{
  tags: readonly string[];
}> = React.memo(({ tags }) => {
  const { t } = useTranslation("shop");
  return (
    <>
      {tags.map((tag) => {
        const Comp = LabelTypes[tag];
        return Comp ? (
          <Comp key={tag}>{t(`products.labels.${tag}`)}</Comp>
        ) : null;
      })}
    </>
  );
});

const ProductTile: React.FC<ProductTileProps> = ({
  style,
  className,
  product,
  largeImage,
  relativeLinks = false,
}) => {
  const { t } = useTranslation("products");
  const [colorVariantId, setColorVariant] = useState<string>();
  const colorVariants = product?.vpsActiveColorVariants ?? [];
  const firstColorVariant = colorVariants[0];
  const currentColorVariant =
    colorVariants.find((c) => c.value === colorVariantId) ?? firstColorVariant;

  if (!product || !currentColorVariant) {
    return null;
  }

  const imageSrc =
    currentColorVariant.products[0]?.media?.[0]?.file?.[
      largeImage ? "largeThumbUrl" : "thumbUrl"
    ] ?? null;

  const availability = getMostOptimisticAvailability(
    currentColorVariant.products.map((p) => p.vpsAvailability),
  );

  const tags = currentColorVariant.products.reduce(
    (acc, p) => [...acc, ...p.tags.filter((t) => !acc.includes(t))],
    [],
  );

  const showDiscountBadge = tags.includes("DISCOUNTED");

  const firstVariant = currentColorVariant.products[0];

  const discount =
    firstVariant.vpsDiscountedCatalogPrice && firstVariant.vpsCatalogPrice
      ? Math.round(
          (1 -
            firstVariant.vpsDiscountedCatalogPrice.amount /
              firstVariant.vpsCatalogPrice.amount) *
            100,
        )
      : 0;

  // if there are multipe products in the current color, link to the proxy with the color
  // otherwise link directly to the variant (no need for color then)
  const showBaseProductInfo = currentColorVariant?.products?.length > 1;

  const productForInfo = showBaseProductInfo
    ? product
    : currentColorVariant?.products[0];
  const color = currentColorVariant?.value;

  const brand =
    product.texts?.brand && product.texts.brand !== "KEINE MARKE"
      ? product.texts.brand
      : null;

  return (
    <ProductTileLink
      product={productForInfo}
      color={color}
      relativeLinks={relativeLinks}
    >
      <Base style={style} className={className} id={productForInfo._id}>
        <HoverEffect />
        <TheIcon availability={availability} />
        <Labels>
          {showDiscountBadge ? (
            <DiscountBadge
              discount={Math.round(discount)}
              isMonthly={currentColorVariant.products.some(isMonthly)}
              isLowPrice={currentColorVariant.products.some(isLowPrice)}
            />
          ) : null}
          <Tags tags={tags} />
        </Labels>
        <div style={{ position: "relative" }}>
          <ProductImage
            src={imageSrc}
            style={{ marginBottom: "1rem" }}
            alt={t(`SEOTexts.product.imageAlt${brand ? "WithBrand" : ""}`, {
              category:
                product.vpsPrimaryAssortmentPath?.assortmentProduct?.assortment
                  ?.texts?.title ?? "",
              productName: product.texts.title,
              brand,
            })}
          />

          <ProductTileColorVariants
            colorOptions={colorVariants}
            setColorVariant={setColorVariant}
            colorVariant={colorVariantId ?? firstColorVariant?.value}
          />
        </div>
        <ProductOverviewPrice colorVariant={currentColorVariant} />

        <Title>
          {product?.texts?.title}
          {brand ? ` von ${brand}` : null}
        </Title>
      </Base>
    </ProductTileLink>
  );
};

export default React.memo(ProductTile);
